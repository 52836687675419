* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  /* background-color: #000; */
}

@font-face {
  font-family: 'Antihero';
  src: local('Antihero'),url(./assets/fonts/Antihero.ttf) format('truetype');
}

@font-face {
  font-family: 'Designer';
  src: local('Designer'),url(./assets/fonts/Designer.otf) format('truetype');
}

.about {
  background-color: #000000;
  overflow: hidden;
}

video {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.header-content {
  position: absolute;
  width: 950px;
  height: 100%;
  top: 15%;
  padding-left: 5vw;
  display: flex;
  flex-direction: column;
  color: white;
  align-items: flex-end;
  font-family: 'fira sans', sans-serif;
  letter-spacing: 1rem;
  font-style: italic;
  line-height: 3rem;
}

.profilePic {
  position: absolute;
  top: 8vh;
  right: 9vw;
  height: auto;
  width: 30vw;
}
.hero_img {
  position: absolute;
  top: 4vh;
  right: 3vw;
  height: auto;
  width: 60vw;
  opacity: 0.3;
}

/* check for usage */
.overlay {
  /* display: hidden; */
  position: absolute;
  width: 100%;
  height: 600px;
  background-color:rgba(0, 0, 0, 0.89);
}

h3 {
  width: 100%;
  display: flex;
  font-size: 1.2rem;
  margin: 0;
  padding: 0;
  letter-spacing: .4rem;
  justify-content: flex-end;
  align-items: flex-end;
}
h2 {
  color: #ffffff;
  font-size: 2.5rem;
}

.headerParagraph {
  width: 100%;
  display: flex;
  font-size: 1.2rem;
  margin: 0;
  padding: 0;
  letter-spacing: .2rem;
  justify-content: flex-end;
  align-items: flex-end;
  text-align: right;
  line-height: 2rem;
}
/* .headerDivider {
  display: flex;
  align-items: flex-end;
  justify-content: flex-end;
  border-top: 4px solid #ffffff;
  border-radius: 5px;
  color:#ffffff;
  align-self: flex-end;
  margin-right: 4%;
  margin-top: 2%;
} */


.spacer {
  aspect-ratio: 960/200;
  color: '#000000';
  margin-top: -150px;
  width: 100wv;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
}

.layer1 {
  background-image: url('./assets/wave-haikei.svg');
  width: 100%;

}

/* check for usage */
/* .section1Right {
  margin-top: 2vw;
  margin-right: 7vw;
  padding: 3vw;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-around;
  align-items: center;
  justify-items: center;
  border: 4px solid black;
  border-radius: 75px;
  background-color: rgba(248,248,248,0.2);
} */
.skillicons {
  width: 100px;
  margin: 1vw;
}

.jsicon {
  width: 75px;
}

.cssicon {
  width: 75px;
}